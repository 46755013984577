import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Typography, Box, Tooltip } from "@mui/material";
import { useSnackbar } from 'notistack';

import { sampleYear, months } from "../../../constants";
import { addRefrigerantsValidation } from './schema';
import { STATUS } from "../../../redux/constants";
import { addRefrigerants, resetAddCombustionStatus, getEmissionInputFormat } from '../../../redux/actions';

import CeroAutoComplete from '../../../components/CeroAutoComplete';
import CeroButton from '../../../components/CeroButton';
import CeroSelect from '../../../components/CeroSelect';
import CeroInput from '../../../components/CeroInput';
import CeroInfoPair from '../../../components/CeroInfoPair'
import useStyles from "./styles";
import DownloadIcon from '@mui/icons-material/Download';
import EmissionUploadDrawer from '../../EmissionList/EmissionUploadDrawer';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const AddRefrigerantsForm = (props) => {
    const { onCancel, onDownload, onUpload, onValidate, uploadDisabled} = props
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [typesOfEmissionFactors, setTypesOfEmissionFactors] = useState([]);

    const isCalculateDone = useSelector(state => state.emission.addRefrigerants.isCalculateDone)
    const facilitiesData = useSelector(state => state.listings.listFacilities.data);
    const addEmissionData = useSelector(state => state.emission.addRefrigerants);
    const emissionInputs = useSelector(state => state.emission.emissionInputs.data);

    const facilitiesList = facilitiesData.map(item => ({ key: item?.id, value: item?.name }));
    const gasType = (emissionInputs.gas_types || []).map(item => ({ key: item?.code, value: item?.name, id: item?.id }));
    const units = (emissionInputs.units || []).map(item => ({ key: item?.name, value: item?.name }));
    const yearList = sampleYear().map(item => ({ id: item.key, label: item.value }));
    const currentYear = new Date().getFullYear().toString()

    const formik = useFormik({
        initialValues: {
            facility: '',
            year: currentYear,
            month: '',
            gasType: '',
            refrigerant: '',
            unit: '',
            amountOfFuel: null,
        },
        validationSchema: addRefrigerantsValidation,
        onSubmit: () => { },
    });

    useEffect(() => {
        dispatch(getEmissionInputFormat('refrigerants'))
        return () => { dispatch(resetAddCombustionStatus()) }
    }, [dispatch])

    useEffect(() => {
        const selectedTypesOfEmissionFactors = (emissionInputs.refrigerants || [])
            .filter(item => item.gas_type_code === formik.values.gasType)
            .map(item => ({ key: item?.id, value: item?.name }));
        setTypesOfEmissionFactors(selectedTypesOfEmissionFactors)
    }, [formik.values.gasType, emissionInputs.refrigerants])

    useEffect(() => {
        if (addEmissionData.status === STATUS.SUCCESS) {
            enqueueSnackbar('Refrigerant added successfully', { variant: 'success' });
            dispatch(resetAddCombustionStatus())
            onCancel('refrigerants');
        } else if (addEmissionData.status === STATUS.ERROR) {
            enqueueSnackbar(addEmissionData.message.message || "We couldn't process your request. Please try again later.", { variant: 'error' });
            dispatch(resetAddCombustionStatus())
        }
    }, [addEmissionData, enqueueSnackbar, dispatch, onCancel])

    const onCalculate = () => {
        const requestData = {
            facility_id: formik.values.facility,
            gas_type_id: gasType.find(item => item.key === formik.values.gasType)?.id,
            year: formik.values.year,
            month: formik.values.month,
            refrigerant_id: formik.values.refrigerant,
            unit: formik.values.unit + '',
            amount: parseFloat(formik.values.amountOfFuel),
            save: false
        }
        dispatch(addRefrigerants(requestData))
    };

    const onAddRefrigerants = () => {
        const requestData = {
            facility_id: formik.values.facility,
            gas_type_id: gasType.find(item => item.key === formik.values.gasType)?.id,
            year: formik.values.year,
            month: formik.values.month,
            refrigerant_id: formik.values.refrigerant,
            unit: formik.values.unit + '',
            amount: parseFloat(formik.values.amountOfFuel),
            save: true
        }
        sessionStorage.setItem('year', requestData.year)
        dispatch(addRefrigerants(requestData))
    };

    const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] = useState(false);
    const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
    };

    const getInfo = () => {
        return(
            <div>
            <Typography variant='body2'>HFC: Hydrofluorocarbons<br/>
            PFC: Perfluorocarbons<br />
            Direct effects: Immediate impact of the released gas on the Earth's energy balance and climate</Typography>
            </div>
        )
    }

    return (
        <>
        <div className={classes.backContainer} onClick={()=>{navigate(-1)}}>
        <ArrowBackIosIcon />
        Back
        </div>
        <Container className={classes.container}>
            <Box className={classes.innerContainer}>
                <Typography className={classes.title} variant="h6" component="div" >Add Refrigerants</Typography>
                <Box className={classes.topContainer}>
                    <Grid container direction='row' wrap='nowrap' justifyContent='space-between' spacing={3}>
                        <Grid item container direction='column' md={6} xs={12}>
                            <CeroSelect
                                required
                                id="facility"
                                key="facility"
                                name="facility"
                                label="Facility"
                                fullWidth
                                options={facilitiesList}
                                selectedValue={formik.values.facility || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.facility && formik.errors.facility}
                            />
                            <CeroSelect
                                required
                                id="refrigerant"
                                key="refrigerant"
                                name="refrigerant"
                                label="Refrigerant"
                                fullWidth
                                options={typesOfEmissionFactors}
                                selectedValue={formik.values.refrigerant || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.refrigerant && formik.errors.refrigerant}
                                disabled={!formik.values.gasType}
                                classes={{ input: classes.input,
                                    container: classes.selectContainer,
                                }}
                            />
                            <CeroAutoComplete
                                id="year"
                                label="Assessment Year"
                                onChange={(e, value) => formik.setFieldValue('year', value.id)}
                                onBlur={formik.handleBlur}
                                error={formik.errors.year}
                                options={yearList}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                value={formik.values.year}
                            />
                            <CeroInput
                                required
                                id="amountOfFuel"
                                key="amountOfFuel"
                                name="amountOfFuel"
                                label="Amount of Refrigerant"
                                value={formik.values.amountOfFuel || ''}
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.amountOfFuel && formik.errors.amountOfFuel}
                                type="number"
                            />
                        </Grid>
                        <Grid item container direction={'column'} md={6} xs={12}>
                            <CeroSelect
                                required
                                id="gasType"
                                name="gasType"
                                label="Gas Type"
                                fullWidth
                                options={gasType}
                                selectedValue={formik.values.gasType || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.gasType && formik.errors.gasType}
                            />
                            <CeroSelect
                                required
                                id="month"
                                name="month"
                                label="Month"
                                fullWidth
                                options={months}
                                selectedValue={formik.values.month || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.month && formik.errors.month}
                            />
                            <CeroSelect
                                required
                                id="unit"
                                key="unit"
                                name="unit"
                                label="Unit"
                                fullWidth
                                options={units}
                                selectedValue={formik.values.unit || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.unit && formik.errors.unit}
                                sortOption={true}
                            />
                        </Grid>
                        <Grid item container direction='column' md={6} xs={4}>
                        <span className={classes.info}>
                            <Tooltip title={getInfo()} classes={{ popper: classes.tooltip }} arrow placement="right-start">
                            <InfoOutlinedIcon className={classes.infoIcon}/></Tooltip>
                        </span>
                        </Grid>
                    </Grid>
                    <CeroButton
                        buttonText="Calculate"
                        className={classes.buttonPrimary}
                        onClick={onCalculate}
                        disabled={!formik.dirty || !formik.isValid}
                    />
                    <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
                </Box>
                {isCalculateDone && <Box className={classes.bottomContainer}>
                    <Typography variant="h6" component="h6" className={classes.previewTitle}>Emission Preview</Typography>
                    <Grid container direction='row' wrap='nowrap' justifyContent='space-between' spacing={8}>
                        <Grid item container direction='column' xs={12} md={6}>
                            <CeroInfoPair title={<>CO<sub>2</sub>e</>} value={`${addEmissionData.data.co2e} tonnes`} />
                        </Grid>
                    </Grid>
                </Box>}
            </Box>
            <Box className={classes.buttonContainer}>
                <CeroButton
                    buttonText="Add Data"
                    disabled={!isCalculateDone || addEmissionData.status === STATUS.RUNNING}
                    className={classes.buttonPrimary}
                    onClick={() => onAddRefrigerants(formik.values)}
                />
                <div className={classes.buttonContainer}>
        <CeroButton
          buttonText="Bulk Data Upload"
          className={classes.bulkButton}
          startIcon={<FileUploadIcon />}
          onClick={() => setIsOpenEmissionTypeUpload(true)}
        />
        <CeroButton
          buttonText="Download Template"
          className={classes.bulkButton}
          startIcon={<DownloadIcon />}
          onClick={onDownload}
        />
        </div>
            </Box>
            <EmissionUploadDrawer
                isOpen={isOpenEmissionTypeUpload}
                onClose={onCloseDrawerUpload}
                onUpload={onUpload}
                onValidate={onValidate}
                uploadDisabled={uploadDisabled}
            />
        </Container>
        </>
    )
}

export default AddRefrigerantsForm;
