import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ArcElement,
  registerables as registerablesJS,
} from "chart.js";
import { getEnergyMaterial } from "../../../redux/actions";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js/auto";
import { useNavigate } from "react-router-dom";

ChartJS.register(...registerablesJS);
ChartJS.register(ArcElement, Tooltip, Legend);

const EnergyMaterialConsumption = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const role = getCookie('role');
    const companyId = getCookie("companyId");
  const roleData = useSelector(
    (state) => state.dashboard.getEnergyMaterial.data
  );

  useEffect(() => {
    dispatch(getEnergyMaterial(props.filter,role,companyId));
  }, [props.filter, dispatch]);

  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  function isFloatingPoint(number) {
    return !Number.isInteger(number);
  }

  useEffect(() => {
    if (roleData?.length === 0) return;

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const splitLabel = (label) => {
      const words = label.split(" ");
      const firstLine = words[0];
      const secondLine = words.slice(1).join(" ");
      return [firstLine, secondLine];
    };

    const tickLabels = roleData.map((item) => {
      const label = capitalizeFirstLetter(
        item.type.replace(/_/g, " ")
      ).replaceAll("_", " ");
      return label.includes(" ") ? splitLabel(label) : label;
    });

    const myChartRef = chartRef?.current?.getContext("2d");

    chartInstance.current = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: tickLabels,
        datasets: [
          {
            label: "Total CO2e",
            data: roleData?.map((item) => item.total_co2e),
            backgroundColor: ["#6FBFBA"],
            barThickness: 30,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            labels: {
              fontColor: "rgba(0,0,0,.4)",
            },
            align: "end",
            position: "bottom",
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.raw
                return label;
              },
            },
          },
          datalabels: {
                display: false,
              },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            title: {
              display: true,
              text: "Energy Source",
              color: "grey",
              font: {
                size: 12,
              },
            },
            maxRotation: 0,
            minRotation: 0,
            maxTicksLimit: 10,
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0,
              lineHeight: 1.5,
              font: {
                size: 10,
              },
            },
          },
          y: {
            ticks: {
              callback: function (value, index, values) {
                return isFloatingPoint(value) ? value.toFixed(1) : value;
              },
            },
            title: {
              display: true,
              text: "Co2e(Ton)",
              color: "grey",
              font: {
                size: 12,
              },
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        aspectRatio: 2,
        layout: {
          padding: {
            left: 20,
            right: 20,
          },
        },
      },
      plugins: [ChartDataLabels],
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [roleData,role]);

  const handleContainerClick = () => {
    navigate("/dashboard/details/emc");
  };

  return (
    <Paper className={classes.container} onClick={handleContainerClick}>
      {(role === "sustainability_manager" || role === "proxy_sustainability_manager" ||
        role === "read_only_user") && (
        <>
          <Typography className={classes.graphTitle}>
            Emissions by Energy Source
          </Typography>
          {roleData?.length > 0 ? (
            <div className={classes.chart}>
              <canvas ref={chartRef} />
            </div>
          ) : (
            <div style={{ minHeight: 250 }}>No Data Available</div>
          )}
        </>
      )}
    </Paper>
  );
};

export default EnergyMaterialConsumption;
