import { Container } from '@mui/material';
import React from 'react'
import useStyles from '../styles';
import CeroItemPair from '../../../components/CeroItemPair';

const PoliticalContributionsApproval = (props) => {
    const classes = useStyles();
    const { emissionData } = props;

    return (
        <Container className={classes.mainContainer}>
        {emissionData && Array.isArray(emissionData) && emissionData?.map((item, index) => (
          <Container key={index} className={classes.container}>
            <CeroItemPair
              title="Has your company made Political Contributions:"
              value={item.any_political_contribution ? item.any_political_contribution : "NA"} 
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Total Amount of Contributions:"
              value={item.amount ? item.amount : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Beneficiary:"
              value={item.beneficiary_of_contribution ? item.beneficiary_of_contribution : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Description of the contributions:"
              value={item.description_of_contribution ? item.description_of_contribution : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Disclosure Explanation:"
              value={item.disclosure_explanation ? item.disclosure_explanation : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            </Container>
        ))}
        </Container>
  )
}

export default PoliticalContributionsApproval