import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Typography,
  Grid,
} from "@mui/material";
import { getSocialEngagement } from "../../../redux/actions";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";

const SocialEngagementGraph = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie("role");
  const companyId = getCookie("companyId");
  const values = useSelector(
    (state) => state.dashboard.getSocialEngagement.data
  );

  useEffect(() => {
    dispatch(getSocialEngagement(props.filter,role,companyId));
  }, [dispatch, props.filter]);

  return (
    <Paper className={classes.container}>
      <Typography className={classes.graphTitle}>
        Social Engagement
      </Typography>
      <Grid container spacing={2} display="flex" style={{height: "100%"}}>
        <Grid item xs={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <div className={classes.circle}>
            <Typography variant="h4" className={classes.value}>{values?.local_commutes?.total_local_communities}</Typography>
          </div>
          <Typography variant="caption">Local Community Engagements</Typography>
        </Grid>
        <Grid item xs={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <div className={classes.circle}>
            <Typography variant="h4" className={classes.value}>{values?.local_commutes?.total_local_communities_contributions}</Typography>
          </div>
          <Typography variant="caption">
           Contributions to Local Communities
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <div className={classes.circle}>
            <Typography variant="h4" className={classes.value}>{values?.political_data?.total_political_contributions}</Typography>
          </div>
          <Typography variant="caption">
           Political Contributions
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SocialEngagementGraph;
