import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography } from "@mui/material";

import CeroSankeyGraph from "../../../components/CeroSankeyGrpah";
import { getFuelSourcesTarget } from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";

const FuelSourceTargetGraph = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie('role');
  const companyId = getCookie("companyId");

  const graphData = useSelector(
    (state) => state.dashboard.getFuelSourcesTarget.data.response
  );

  const isLoading =
    useSelector((state) => state.dashboard.getFuelSourcesTarget.status) ===
    STATUS.RUNNING;

  useEffect(() => {
    dispatch(getFuelSourcesTarget(props.filter,role,companyId));
  }, [dispatch, props.filter]);

  return (
    <Paper className={classes.container}>
      <Typography className={classes.graphTitle}>
        Fuel Source Emission
      </Typography>
      <CeroSankeyGraph
        data={graphData}
        width={window.innerWidth - 256 - 36 * 2}
        isLoading={isLoading}
        noDataText="Fuel Source Data not available for this filter"
      />
    </Paper>
  );
};

export default FuelSourceTargetGraph;
