import { all, put, call, takeLatest } from 'redux-saga/effects'

import { deleteCookie, setCookie } from '../../services/cookie'
import { request } from '../../services/client'
import { ActionTypes } from '../constants/actions';
import { APIEndpoints } from '../constants';

/**
 * Login
 */


export function* signupTrade(action) {
  try {
    const { signupDetails } = action.payload
    const response = yield call(request, APIEndpoints.SIGNUP_TRADE, {
      method: 'POST',
      payload: { ...signupDetails }
    })
    yield put({
      type: ActionTypes.SIGNUP_TRADE_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.SIGNUP_TRADE_FAILURE,
      payload: err.message
    })
  }
}

export function* loginTrade(action) {
  try {
    const { email, password } = action.payload
    let loginFormData = new URLSearchParams();
    loginFormData.append('username', email)
    loginFormData.append('password', password)
    const response = yield call(request, APIEndpoints.LOGIN_TRADE, {
      method: 'POST',
      isFormData: true,
      disableAuthorization: true,
      payload: loginFormData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
    yield setCookie('read_only_mode', response.status)
    yield setCookie('auth_token_admin', response.access_token)
    yield setCookie('access_token_expiry', response.access_token_expiry)
    yield setCookie('user_details', JSON.stringify(response))
    yield setCookie('role', [response.role[0]])
    yield setCookie('availableRoles', response.role)
    yield setCookie('availableRoles', response.role)
    yield setCookie('type', response.type)
    yield deleteCookie('session_expired')
    localStorage.setItem('password', password)
    yield put({
      type: ActionTypes.LOGIN_TRADE_SUCCESS,
      payload: response,
      role: [response.role[0]]
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LOGIN_TRADE_FAILURE,
      payload: err.message
    })
  }
}

export function* industryTypes(action) {
  try {
    const response = yield call(request, APIEndpoints.INDUSTRY_TYPES, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.INDUSTRY_TYPES_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.INDUSTRY_TYPES_FAILURE,
      payload: err.message,
    });
  }
}

export function* getLocations() {
  try {
    const response = yield call(request, APIEndpoints.GET_LOCATIONS, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_LOCATIONS_SUCCESS,
      payload: response.countries,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_LOCATIONS_FAILURE,
      payload: err.message,
    });
  }
}

export function* sellCarbonCredits(action) {
  try {
      const { requestData } = action.payload
      const response = yield call(request, APIEndpoints.SELL_CARBON_CREDITS, {
          method: 'POST',
          payload: requestData
      })

      yield put({
          type: ActionTypes.SELL_CARBON_CREDITS_SUCCESS,
          payload: response,
          save: requestData.save
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.SELL_CARBON_CREDITS_FAILURE,
          payload: err
      })
  }
}

export function* getCarbonCreditsAdded(action) {
  try {
    const response = yield call(request, APIEndpoints.GET_CARBON_CREDITS_ADDED, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_CARBON_CREDITS_ADDED_SUCCESS,
      payload: response.carbon_credits_list,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_CARBON_CREDITS_ADDED_FAILURE,
      payload: err.message,
    });
  }
}

export function* buyCarbonCreditList(action) {
  const {requestData} = action.payload
  try {
    const response = yield call(request, APIEndpoints.BUY_CARBON_CREDIT_LIST(requestData), {
      method: "GET",
    });
    yield put({
      type: ActionTypes.BUY_CARBON_CREDIT_LIST_SUCCESS,
      payload: response.carbon_credits_list,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.BUY_CARBON_CREDIT_LIST_FAILURE,
      payload: err.message,
    });
  }
}

export function* getProjectDetails(action) {
  const { projectId } = action.payload
  try {
    const response = yield call(request, APIEndpoints.GET_PROJECT_DETAILS(projectId), {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_PROJECT_DETAILS_SUCCESS,
      payload: response.carbon_credits,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_PROJECT_DETAILS_FAILURE,
      payload: err.message,
    });
  }
}

export function* getTradeAccount(action) {
  try {
      const response = yield call(request, APIEndpoints.GET_TRADE_ACCOUNT, {
          method: 'GET'
      })
      yield put({
          type: ActionTypes.GET_TRADE_ACCOUNT_SUCCESS,
          payload: response
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.GET_TRADE_ACCOUNT_FAILURE,
          payload: err.error
      })
  }
}

export function* updateTradeAccount(action) {
  try {
      const { requestData } = action.payload
      const response = yield call(request, APIEndpoints.UPDATE_TRADE_ACCOUNT, {
          method: 'PUT',
          payload: requestData 
      })
      yield put({
          type: ActionTypes.UPDATE_TRADE_ACCOUNT_SUCCESS,
          payload: response
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.UPDATE_TRADE_ACCOUNT_FAILURE,
          payload: err
      })
  }
}

export function* getDashboardWidget(action) {
  try {
    const response = yield call(request, APIEndpoints.GET_DASHBOARD_WIDGET, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_DASHBOARD_WIDGET_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_DASHBOARD_WIDGET_FAILURE,
      payload: err.message,
    });
  }
}

export function* getCarbonEmissions(action) {
  try {
    const response = yield call(request, APIEndpoints.GET_CARBON_EMISSIONS, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_CARBON_EMISSIONS_SUCCESS,
      payload: response.carbon_emission_data,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_CARBON_EMISSIONS_FAILURE,
      payload: err.message,
    });
  }
}
export default function* root() {
  yield all([
    takeLatest(ActionTypes.SIGNUP_TRADE, signupTrade),
    takeLatest(ActionTypes.LOGIN_TRADE, loginTrade),
    takeLatest(ActionTypes.INDUSTRY_TYPES, industryTypes),
    takeLatest(ActionTypes.GET_LOCATIONS, getLocations),
    takeLatest(ActionTypes.SELL_CARBON_CREDITS, sellCarbonCredits),
    takeLatest(ActionTypes.GET_CARBON_CREDITS_ADDED, getCarbonCreditsAdded),
    takeLatest(ActionTypes.BUY_CARBON_CREDIT_LIST, buyCarbonCreditList),
    takeLatest(ActionTypes.GET_PROJECT_DETAILS, getProjectDetails),
    takeLatest(ActionTypes.GET_TRADE_ACCOUNT, getTradeAccount),
    takeLatest(ActionTypes.UPDATE_TRADE_ACCOUNT, updateTradeAccount),
    takeLatest(ActionTypes.GET_DASHBOARD_WIDGET, getDashboardWidget),
    takeLatest(ActionTypes.GET_CARBON_EMISSIONS, getCarbonEmissions),
  ])
}