import { Typography, Box, Container } from "@mui/material";
import React from "react";
import useStyles from "../styles";
import CeroItemPair from "../../../components/CeroItemPair";

const SubsidiesAndFinancialAssistanceApproval = (props) => {
  const classes = useStyles();
  const { emissionData } = props;

  return (
    <Container className={classes.mainContainer}>
      {emissionData && Array.isArray(emissionData) && emissionData?.map((item, index) => (
          <Container key={index} className={classes.container}>
            <CeroItemPair
              title="Total Financial Assistance Received:"
              value={`$${item.financial_assistant_received}` || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Type of Financial Assistance:"
              value={item.type_financial_assistant || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />
            <Typography variant='subtitle2' className={classes.subsidiesHeading}>Total monetary value of financial assistance received from each Facility:</Typography>
            <Box className={classes.amountDistribution}>
              {item.financial_assistant_by_facilities?.map(elem=>(
                <CeroItemPair
                title={elem.facility_name}
                value={`$${elem.amount}` || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.subsidiesTitle,
                  value: classes.value,
                }}
              />
              ))}
            </Box>
            <CeroItemPair
              title="Indicate whether any government is present in the shareholding structure of the organization:"
              value={item.any_government_present_in_shareholder || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />
            {item.any_government_present_in_shareholder === "Yes" && <CeroItemPair
              title="Details:"
              value={item.details || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />}
              
          </Container>
))}
    </Container>
  );
};

export default SubsidiesAndFinancialAssistanceApproval;
