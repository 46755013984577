import { Typography, Container } from "@mui/material";
import React from "react";
import CeroInfoPair from "../../../components/CeroInfoPair";
import useStyles from "../styles";

const ManagementDiversityApproval = (props) => {
  const classes = useStyles();
  const { emissionData } = props;

  return (
    <Container className={classes.mainContainer}>
          <Container className={classes.container}>
      {emissionData && emissionData?.based_on_gender?.map((item, index) => (
        <>
            <Typography variant='subtitle1' className={classes.heading}>Management Diversity by Gender</Typography>
            <CeroInfoPair
              title="Percentage of Management Members identifying as Male:"
              value={`${item.male}%` || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.commonTitle,
                value: classes.value,
              }}
            />
            <CeroInfoPair
              title="Percentage of Management Members identifying as Female:"
              value={`${item.female}%` || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.commonTitle,
                value: classes.value,
              }}
            />
            <CeroInfoPair
              title="Percentage of Management Members identifying as Others"
              value={`${item.other}%` || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.commonTitle,
                value: classes.value,
              }}
            />
            </>
))}
          {emissionData && emissionData?.based_on_age?.map((item, index) => (
            <>
            <Typography variant='subtitle1' className={classes.heading}>Management Diversity by Age</Typography>
            <CeroInfoPair
              title="Percentage of Management Members under 30 years:"
              value={`${item.under_30}%` || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.commonTitle,
                value: classes.value,
              }}
            />
            <CeroInfoPair
              title="Percentage of Management Members between 30&50 years:"
              value={`${item.between_30_50}%` || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.commonTitle,
                value: classes.value,
              }}
            />
            <CeroInfoPair
              title="Percentage of Management Members above 50 years:"
              value={`${item.over_50}%` || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.commonTitle,
                value: classes.value,
              }}
            />
            </>
))}
          </Container>
    </Container>
  );
};

export default ManagementDiversityApproval;
