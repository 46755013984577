import { Container } from '@mui/material';
import React from 'react'
import useStyles from '../styles';
import CeroItemPair from '../../../components/CeroItemPair';

const SupplierScreeningApproval = (props) => {
    const classes = useStyles();
    const { emissionData } = props;

    return (
        <Container className={classes.mainContainer}>
        {emissionData && Array.isArray(emissionData) && emissionData?.map((item, index) => (
          <Container key={index} className={classes.container}>
            <CeroItemPair
              title="Number of suppliers screened using Environmental Criteria:"
              value={item.suppliers_screened_using_env_criteria ? item.suppliers_screened_using_env_criteria : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Environmental Criteria:"
              value={item.environment_criteria_list ? item.environment_criteria_list?.map((criteria, index) => (
                <li className={classes.list} key={index}>{index + 1}. {criteria}</li>)) : "NA"} 
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            {item.environment_criteria_list &&
                  item.environment_criteria_list.includes("Others") && (
                    <CeroItemPair
                      title="Environmental Criteria Details:"
                      value={
                        item.enviormental_criteria_details
                          ? item.enviormental_criteria_details
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.supplierScreenTitle,
                        value: classes.value,
                      }}
                    />
                  )}
            <CeroItemPair
              title="Describe Negative Environmental Impacts:"
              value={item.negative_env_impact ? item.negative_env_impact : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Actions taken to Address Negative Impacts:"
              value={item.action_taken_address_env_criteria ? item.action_taken_address_env_criteria : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Number of suppliers screened using Social Criteria:"
              value={item.suppliers_screened_using_social_criteria ? item.suppliers_screened_using_social_criteria : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Social Criteria:"
              value={item.social_criteria_list ? item.social_criteria_list?.map((criteria, index) => (
                <li className={classes.list} key={index}>{index + 1}. {criteria}</li>)) : "NA"} 
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            {item.social_criteria_list &&
                  item.social_criteria_list.includes("Others") && (
                    <CeroItemPair
                      title="Social Criteria Details:"
                      value={
                        item.social_criteria_details
                          ? item.social_criteria_details
                          : "NA"
                      }
                      classes={{
                        container: classes.infoContainer,
                        title: classes.supplierScreenTitle,
                        value: classes.value,
                      }}
                    />
                  )}
            <CeroItemPair
              title="Describe Negative Social Impacts:"
              value={item.negative_social_impact ? item.negative_social_impact : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Actions taken to Address Negative Impacts:"
              value={item.action_taken_address_social_criteria ? item.action_taken_address_social_criteria : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            </Container>
        ))}
        </Container>
  )
}

export default SupplierScreeningApproval