import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
  Link,
} from "@mui/material";
import DashboardLayout from "../../../layouts/DashboardLayout";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import CeroTable from "../../../components/CeroTable";
import { buyCarbonCreditList } from "../../../redux/actions";
import SearchBox from "../../../components/SearchBox";

export const initiativesColumns = [
    {
      columnKey: "project_name",
      columnId: "project_name",
      columnHeader: "Project Name",
      width: "15vw",
    },
    {
      columnKey: "location",
      columnId: "location",
      columnHeader: "Location",
      width: "10vw",
    },
    {
      columnKey: "certification",
      columnId: "certification",
      columnHeader: "Certification",
      width: "10vw",
    },
    {
        columnKey: "project_type",
        columnId: "project_type",
        columnHeader: "Project Type",
        width: "15vw",
      },
      {
        columnKey: "co2_reduction",
        columnId: "co2_reduction",
        columnHeader: "Co2 Reductions(tons)",
        width: "10vw",
      },
      {
        columnKey: "action",
        columnId: "action",
        columnHeader: "More Information",
        width: "10vw",
      },
  ];

const TradeBuy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');

  const carbonCreditList = useSelector(state => state.trade.buyCarbonCreditList.data);

const getInitiativesList = () => {
    if (!Array.isArray(carbonCreditList)) {
      return [];
    }

    return carbonCreditList.map((item) => ({
      ...item,
      action: (
        <Link
          className={classes.link}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            navigate(`/trade/carboncredits-added/${item.id}`);
            // onSelectData(item.id);
          }}
        >
          Click here
        </Link>
      ),
    }));
  };


  useEffect(() => {
    dispatch(buyCarbonCreditList(searchText))
  }, [dispatch,searchText])
  
  return (
    <DashboardLayout classes={{ childContainer: classes.childContainer }}>
      <>
        <div
          className={classes.backContainer}
          onClick={() => {
            navigate(-1);
          }}
        ><ArrowBackIosIcon />Back</div>
             <Box width={220} mb={6}>
            <SearchBox
              placeholder="Search Projects"
              onChange={({ target }) => setSearchText(target.value)}
              value={searchText}
              classes={{ container: classes.searchTextContainer }}
            />
          </Box>
        {/* <Container className={classes.tableContainer}> */}
            <CeroTable
              columns={initiativesColumns}
              data={getInitiativesList()}
              hasMore={false}
              loading={false}
              classes={{ tableContainer: classes.tableInnerContainer }}
            />
          {/* </Container> */}
      </>
    </DashboardLayout>
  );
};

export default TradeBuy;
