import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ArcElement,
  registerables as registerablesJS,
} from "chart.js";
import {
  getAntiCorruptionDisclosure,
} from "../../../redux/actions";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js/auto";

ChartJS.register(...registerablesJS);
ChartJS.register(ArcElement, Tooltip, Legend);

const AntiCorruptionDisclosureGraph = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const role = getCookie("role");
  const companyId = getCookie("companyId");
  const employeeData = useSelector(
    (state) => state.dashboard.getAntiCorruptionDisclosure.data.response
  );

  useEffect(() => {
    dispatch(getAntiCorruptionDisclosure(props.filter,role,companyId));
  }, [dispatch, props.filter]);

  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (employeeData?.length === 0) return;

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const myChartRef = chartRef.current.getContext("2d");

    chartInstance.current = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: employeeData?.map((item) => item.year),

        datasets: [
          {
            label: "Incidents Reported",
            backgroundColor: "#6FBFBA",
            data: employeeData?.map((item) => item.number_of_incident),
            barThickness: (employeeData?.length > 10) ? 15 : 30,
          },
          {
            label: "Operations Assessed",
            backgroundColor: "#846BB5",
            data: employeeData?.map((item) => item.number_of_operation),
            barThickness: (employeeData?.length > 10) ? 15 : 30,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: "bottom",
            align: "end",
            labels: {
              color: "black",
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            ticks: {
            },
            title: {
              display: true,
              text: "Count",
              color: "grey",
              font: {
                size: 12,
              },
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        aspectRatio: 2,
        layout: {
          padding: {
            left: 20,
            right: 20,
            bottom: 20,
          },
        },
      },
      plugins: [ChartDataLabels],
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [employeeData]);
  return (
    <Paper className={classes.container}>
      <Typography className={classes.graphTitle}>
        Anti-Corruption Disclosure
      </Typography>
      <div className={classes.chart}>
        <canvas ref={chartRef} />
      </div>
    </Paper>
  );
};

export default AntiCorruptionDisclosureGraph;
