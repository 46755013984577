import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import clsx from "clsx";
import useStyles from "./styles";
import { Box, Typography } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CeroSearchWithMultiselect = (props) => {
  const classes = useStyles();
  const handleChange = (event, value) => {    
    props.onChange(value);
  };

  const boxStyles = clsx(classes.container, props.error && classes.errorBox);

  return (
    <Box className={boxStyles}>
      <Autocomplete
        multiple
        id={props.id}
        options={props.options || []}
        disableCloseOnSelect
        getOptionLabel={(option) => option.value}
        onChange={handleChange}
        value={props.value}
        isOptionEqualToValue={(option, value) => option.value === value.value} 
        classes={{
          root: clsx(
            props.error ? props.classes?.selectError : "",
            props.classes?.root
          ),
        }}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox
                className={classes.indeterminateColor}
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.title}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            placeholder={props.placeholder}
            className={props.classes.textField}
          />
        )}
      />
      {!!props.error && (
        <Typography
          className={clsx(classes.text, classes.error, props.classes?.error)}
        >
          {props.error}
        </Typography>
      )}
    </Box>
  );
};

export default CeroSearchWithMultiselect;
