import { all, put, call, takeLatest } from "redux-saga/effects";

import { request } from "../../services/client";
import { ActionTypes } from "../constants/actions";
import { APIEndpoints } from "../constants";

export function* getReportList({ payload }) {
  try {
    // const apiEndpoint = `${APIEndpoints.LIST_REPORTS}?${stringifyQuery(payload)}`
    const response = yield call(request, APIEndpoints.LIST_REPORTS, {
      method: "POST",
      payload: payload
    });
    yield put({
      type: ActionTypes.GET_ALL_REPORTS_SUCCESS,
      payload: response.reports,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ALL_REPORTS_FAILURE,
      payload: err,
    });
  }
}

export function* getReportDetails({ payload }) {
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_REPORT_DETAILS(payload.id),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_REPORT_DETAILS_SUCCESS,
      payload: response.report,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_REPORT_DETAILS_FAILURE,
      payload: err,
    });
  }
}
export function* deleteReport({ payload }) {
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_REPORT_DETAILS(payload.id),
      {
        method: "DELETE",
      }
    );
    yield put({
      type: ActionTypes.DELETE_REPORT_SUCCESS,
      payload: response.report,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.DELETE_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* createReport({ payload }) {
  try {
    const response = yield call(request, APIEndpoints.CREATE_REPORT, {
      method: "POST",
      payload,
    });
    yield put({
      type: ActionTypes.GET_ALL_REPORTS,
      payload: { },
    });
    yield put({
      type: ActionTypes.CREATE_REPORT_SUCCESS,
      payload: response.report,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CREATE_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* updateReport({ payload }) {
  try {
    const { id, body } = payload;
    const response = yield call(request, APIEndpoints.UPDATE_REPORT(id), {
      method: "PUT",
      payload: {
        body,
      },
    });
    yield put({
      type: ActionTypes.GET_REPORT_DETAILS,
      payload: { id },
    });
    yield put({
      type: ActionTypes.UPDATE_REPORT_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_REPORT_FAILURE,
      payload: err,
    });
  }
}
export function* assignReport({ payload }) {
  try {
    const { email,save,report_id } = payload.id;
    const response = yield call(request, APIEndpoints.ASSIGN_REPORT(report_id), {
      method: "POST",
      payload: {
        email,save
      },
    });
    yield put({
      type: ActionTypes.ASSIGN_REPORT_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ASSIGN_REPORT_FAILURE,
      payload: err,
    });
  }
}
export function* generateAuditedReport({ payload }) {
  try {
    const { data } = payload;
    const response = yield call(request, payload.isSustainabilityManager?APIEndpoints.GENERATE_AUDITED_REPORT_BY_SM(payload):APIEndpoints.GENERATE_AUDITED_REPORT(payload), {
      method: "POST",
      payload: data
    });
    yield put({
      type: ActionTypes.GENERATE_AUDITED_REPORT_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GENERATE_AUDITED_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* getStakeholderReportsList(action) {
  try {
    const { companyId, year } = action.payload
    const response = yield call(request, APIEndpoints.GET_STAKEHOLDER_REPORTS_LIST(companyId,year), {
      method: 'GET',
    })
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_REPORTS_LIST_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_REPORTS_LIST_FAILURE,
      payload: err.message
    })
  }
}
export function* getStakeholderAssignedReports(action) {
  try {
    const { companyId, reportId } = action.payload
    const response = yield call(request, APIEndpoints.GET_STAKEHOLDER_ASSIGNED_REPORTS(companyId,reportId), {
      method: 'GET',
    })
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_ASSIGNED_REPORTS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_ASSIGNED_REPORTS_FAILURE,
      payload: err.message
    })
  }
}
export function* getStakeholderAuditedReports(action) {
  try {
    const { companyId, year } = action.payload
    const response = yield call(request, APIEndpoints.GET_STAKEHOLDER_AUDITED_REPORTS(companyId,year), {
      method: 'GET',
    })
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_AUDITED_REPORTS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_AUDITED_REPORTS_FAILURE,
      payload: err.message
    })
  }
}
export function* getStakeholderTopicsSummary(action) {
  try {
    const { companyId, type, payload } = action.payload;
    const response = yield call(request, APIEndpoints.GET_STAKEHOLDER_TOPICS_SUMMARY(companyId,type), {
      method: 'POST',
      payload: payload
    })
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_TOPICS_SUMMARY_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_TOPICS_SUMMARY_FAILURE,
      payload: err.message
    })
  }
}
export function* getStakeholderTopicsData(action) {
  try {
    const { companyId,topic_type, year } = action.payload
    const response = yield call(request, APIEndpoints.GET_STAKEHOLDER_TOPICS_DATA(companyId,topic_type,year), {
      method: 'GET',
    })
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_TOPICS_DATA_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_TOPICS_DATA_FAILURE,
      payload: err.message
    })
  }
}

export function* getCumulativeTopics(action) {
  try {
    const { emissionType, year, isAuditor, facilities, period } = action.payload
    const response = yield call(request, APIEndpoints.GET_CUMULATIVE_TOPICS, {
      method: "POST",
      payload: {
        "year": year,
        "facilities": facilities,
        "emission_types": emissionType,
        "period":period
    },
    });
    yield put({
      type: ActionTypes.GET_CUMULATIVE_TOPICS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_CUMULATIVE_TOPICS_FAILURE,
      payload: err,
    });
  }
}

export function* getStakeholderCumulativeData(action) {
  try {
    const { companyId, reportId, type } = action.payload;
    const response = yield call(request, APIEndpoints.GET_STAKEHOLDER_CUMULATIVE_DATA(companyId, reportId, type), {
      method: 'POST',
      payload: {}
    })
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_CUMULATIVE_DATA_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_CUMULATIVE_DATA_FAILURE,
      payload: err.message
    })
  }
}

export function* getReportListTopics(action) {
  try {
    const { emissionType, filter, isAuditor, facilities, period, country, distribution } =
      action.payload;
    const payload = filter;
    const apiEndpoint = APIEndpoints.GET_REPORT_LIST_TOPICS(emissionType);
    if (!payload.month) delete payload.month;
    if (!payload.facility_id) delete payload.facility_id;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: {
            year: filter.year,
            //  "query": "",
            skip: filter.skip || 0,
            limit: filter.limit || 40,
            month: filter.month,
            facilities: facilities,
            period: period,
            country: country,
            distribution: distribution,
          },
    });
    yield put({
      type: ActionTypes.GET_REPORT_LIST_TOPICS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_REPORT_LIST_TOPICS_FAILURE,
      payload: err,
    });
  }
}

export function* getReportEmissionDetails(action) {
  try {
    const { emission_type, emission_id, distribution, classification } = action.payload.emission_type;
    const apiEndpoint = APIEndpoints.GET_REPORT_EMISSION_DETAILS(
      emission_type,
      emission_id,
      distribution,
      classification
    );
    const response = yield call(request, apiEndpoint, {
      method: "GET"
    })
    yield put({
      type: ActionTypes.GET_REPORT_EMISSION_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_REPORT_EMISSION_DETAILS_FAILURE,
      payload: err,
    });
  }
}

export function* getReportBlob({ payload }) {
  try {
    const response = yield call(request, APIEndpoints.GET_REPORT_BLOB(payload.id),
      {
        method: "GET",
      });
    yield put({
      type: ActionTypes.GET_REPORT_BLOB_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_REPORT_BLOB_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_ALL_REPORTS, getReportList),
    takeLatest(ActionTypes.GET_REPORT_DETAILS, getReportDetails),
    takeLatest(ActionTypes.DELETE_REPORT, deleteReport),
    takeLatest(ActionTypes.CREATE_REPORT, createReport),
    takeLatest(ActionTypes.UPDATE_REPORT, updateReport),
    takeLatest(ActionTypes.ASSIGN_REPORT, assignReport),
    takeLatest(ActionTypes.GENERATE_AUDITED_REPORT, generateAuditedReport),
    takeLatest(ActionTypes.GET_STAKEHOLDER_REPORTS_LIST, getStakeholderReportsList),
    takeLatest(ActionTypes.GET_STAKEHOLDER_ASSIGNED_REPORTS, getStakeholderAssignedReports),
    takeLatest(ActionTypes.GET_STAKEHOLDER_AUDITED_REPORTS, getStakeholderAuditedReports),
    takeLatest(ActionTypes.GET_STAKEHOLDER_TOPICS_SUMMARY, getStakeholderTopicsSummary),
    takeLatest(ActionTypes.GET_STAKEHOLDER_TOPICS_DATA, getStakeholderTopicsData),
    takeLatest(ActionTypes.GET_CUMULATIVE_TOPICS, getCumulativeTopics),
    takeLatest(ActionTypes.GET_STAKEHOLDER_CUMULATIVE_DATA, getStakeholderCumulativeData),
    takeLatest(ActionTypes.GET_REPORT_LIST_TOPICS, getReportListTopics),
    takeLatest(ActionTypes.GET_REPORT_EMISSION_DETAILS, getReportEmissionDetails),
    takeLatest(ActionTypes.GET_REPORT_BLOB, getReportBlob),
  ]);
}
