import { Container } from "@mui/material";
import React from "react";
import useStyles from "../styles";
import CeroItemPair from "../../../components/CeroItemPair";

const AntiCompatitiveDisclosureApproval = (props) => {
  const classes = useStyles();
  const { emissionData } = props;
  return (
    <Container className={classes.mainContainer}>
      {emissionData && Array.isArray(emissionData) && emissionData?.map((item, index) => (
          <Container key={index} className={classes.container}>
            <CeroItemPair
              title="Number of legal actions:"
              value={item.number_of_legal_action || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Describe the main outcomes of completed legal actions, including any decisions or judgments:"
              value={item.outcomes_of_legal_action || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Corrective Action:"
              value={item.corrective_action || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
          </Container>
))}
    </Container>
  );
};

export default AntiCompatitiveDisclosureApproval;
