import { createActions } from "redux-actions";

import { ActionTypes } from "../constants/actions";

export const {
  userLogin,
  userSignUp,
  getForgotPasswordOtp,
  resetPassword,
  changePassword,
  refreshToken,
  userLogout,
  resetAuthStatus,
  userInviteLogin,
  auditorSignUp,
  userAutoLogout,
  getRefreshToken,
  userActiveRole,
  setActiveRole,
  generalUserLogin,
  superAdminTwofaValidation,
  resendOtp,
  clearOtp,
  proxySustainabilityManagerLogin,
  switchBackToSuperAdmin,
  resetAuthentication,
  adminTwofaOtpGenerate,
  adminTwofaOtpSubmit,
  adminPasswordSubmit,
  resetSignupAction,
  redirectCarbonCreditPool,
  tradeLoginArroUser
} = createActions({
  [ActionTypes.USER_LOGIN]: (userType, email, password) => ({
    userType,
    email,
    password,
  }),
  [ActionTypes.USER_SIGN_UP]: (signupDetails) => ({ signupDetails }),
  [ActionTypes.GET_FORGOT_PASSWORD_OTP]: (email) => ({ email }),
  [ActionTypes.RESET_PASSWORD]: (email, otp, password) => ({
    email,
    otp,
    password,
  }),
  [ActionTypes.CHANGE_PASSWORD]: (oldPassword, password) => ({
    oldPassword,
    password,
  }),
  [ActionTypes.REFRESH_TOKEN]: () => ({}),
  [ActionTypes.USER_LOGOUT]: (email) => ({email}),
  [ActionTypes.RESET_AUTH_STATUS]: () => ({}),
  [ActionTypes.USER_INVITE_LOGIN]: (
    userId,
    code,
    name,
    password,
    userType
  ) => ({ userId, code, name, password, userType }),
  [ActionTypes.AUDITOR_SIGN_UP]: (signupDetails) => ({ signupDetails }),
  [ActionTypes.USER_AUTO_LOGOUT]: (autoLogggedOut) => ({ autoLogggedOut }),
  [ActionTypes.GET_REFRESH_TOKEN]: () => ({}),
  [ActionTypes.USER_ACTIVE_ROLE]: (role) => role,
  [ActionTypes.SET_ACTIVE_ROLE]: (role) => ({ role }),
  [ActionTypes.GENERAL_USER_LOGIN]: (email, password) => ({ email, password }),
  [ActionTypes.SUPER_ADMIN_TWOFA_VALIDATION]: (otp, userId) => ({
    otp,
    userId,
  }),
  [ActionTypes.CLEAR_OTP]: (userId) => ({ userId }),
  [ActionTypes.RESEND_OTP]: (userId) => ({ userId }),
  [ActionTypes.PROXY_SUSTAINABILITY_MANAGER_LOGIN]: (companyId) => ({
    companyId,
  }),
  [ActionTypes.SWITCH_BACK_TO_SUPER_ADMIN]: (userId) => ({
    userId,
  }),
  [ActionTypes.RESET_AUTHENTICATION]: () => ({}),
  [ActionTypes.ADMIN_TWOFA_OTP_GENERATE]: (email) => ({
    email
  }),
  [ActionTypes.ADMIN_TWOFA_OTP_SUBMIT]: (email,otp) => ({
    email,
    otp
  }),
  [ActionTypes.ADMIN_PASSWORD_SUBMIT]: (email, password) => ({
    email,
    password
  }),
  [ActionTypes.RESET_SIGNUP_ACTION]: () => ({}),
  [ActionTypes.REDIRECT_CARBON_CREDIT_POOL]: () => ({}),
  [ActionTypes.TRADE_LOGIN_ARRO_USER]: (displayCarbonCredit) => ({displayCarbonCredit}),

});
