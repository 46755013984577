import { Container } from "@mui/material";
import React from "react";
import useStyles from "../styles";
import CeroItemPair from "../../../components/CeroItemPair";

const AntiCorruptionDisclosureApproval = (props) => {
  const classes = useStyles();
  const { emissionData } = props;

  return (
    <Container className={classes.mainContainer}>
      {emissionData && Array.isArray(emissionData) && emissionData?.map((item, index) => (
          <Container key={index} className={classes.container}>
            <CeroItemPair
              title="Risk of corruption:"
              value={item.is_organisation_accessed_for_corruption || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Number of operations assessed: "
              value={item.number_of_operation || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Number of Incidents reported:"
              value={item.number_of_incident || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Describe any significant risks related to corruption identified during the assessment:"
              value={item.risk_related_corruption || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Describe any confirmed incidents of corruption within your organization and actions taken to address them:"
              value={item.description_of_corruption || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.antiCorruptionDisclosure,
                value: classes.value,
              }}
            />
              
          </Container>
))}
    </Container>
  );
};

export default AntiCorruptionDisclosureApproval;
