import React, { useEffect, useState } from "react";
import { Card, Container, Grid, Typography } from "@mui/material";

import DashboardLayout from "../../layouts/DashboardLayout";
import DashboardHeader from "./DashboardHeader";
import TotalEmissionChart from "./TotalEmissionChart";
import EmissionChart from "./EmissionChart";
import GeographicalChart from "./GeographicalChart";
import Statistics from "./Statistics";
import useStyles from "./styles";
import FuelSourceGraph from "./FuelSourceGraph";
import AuditTrends from "../AuditorDashboard/AuditTrends";
import { getCookie } from "../../services/cookie";
import RoleDistributionGraph from "./RoleDistributionGraph";
import InitiativeTrackingGraph from "./InitiativeTrackingGraph";
import EnergyMaterialConsumption from "./EnergyMaterialConsumption";
import FuelSourceTargetGraph from "./FuelSourcesSankeyGraph";
import EmissionsCard from "./EmissionsCard";
import DiversityGraph from "./DiversityGraph";
import DevelopmentTrainingGraph from "./DevelopmentTrainingGraph";
import SocialEngagementGraph from "./SocialEngagementGraph";
import EmployeeHireResignGraph from "./EmployeeHireResignGraph";
import BoardDiversityGraph from "./BoardDiversityGraph";
import ManagementDiversityGraph from "./ManagementDiversityGraph";
import EmployeeTurnoverGraph from "./EmployeeTurnoverGraph";
import EconomicGraph from "./EconomicGraph";
import EthicalBehaviourGraph from "./EthicalBehaviourGraph";
import AntiCorruptionDisclosureGraph from "./AntiCorruptionDisclosureGraph";
import AntiCorruptionTrainingGraph from "./AntiCorruptionTrainingGraph";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { getNotifications, stakeholderCompanyDetails } from "../../redux/actions";
import DashboardNotification from "./DashboardNotification";

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie("role");
  const [filter, setFilter] = useState({ duration: "last_five_years" });
  const [value, setValue] = useState(0);
  const company = useSelector((state) => state.dashboard.companyDetails.data);
  const notificationData = useSelector((state) => state.dashboard.getNotifications.data);

  const onApplyFilter = (filter) => {
    setFilter(filter);
  };

  const companyId = Cookies.get("companyId");

  useEffect(() => {
    if (role === "read_only_user")
      dispatch(stakeholderCompanyDetails(companyId));
  }, [dispatch, role, companyId]);

  const timeStamp = Date.now()

  useEffect(() => {
      if(role !== "read_only_user" && role !== "auditor" && role !== "proxy_sustainability_manager"){
        dispatch(getNotifications(timeStamp));
      }
  }, [dispatch, role]);

  const renderChartsBasedOnRole = () => {
    switch (role) {
      case "facility_manager":
        return (
          <Grid
            container
            rowSpacing={-2}
            columnSpacing={5}
            className={classes.gridContainer}
          >
            <Grid xs={4.8} item>
              <GeographicalChart filter={filter} />
            </Grid>
            <Grid xs={7.2} item>
              <FuelSourceGraph filter={filter} />
            </Grid>
            <Grid xs={4.8} item>
              <EmissionChart filter={filter} />
            </Grid>
            <Grid xs={7.2} item>
              <AuditTrends filter={filter} />
            </Grid>
          </Grid>
        );
      case "sustainability_manager":
        if (value === 0) {
          return (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              className={classes.gridContainer}
            >
              <Grid xs={4.8} item>
                <EmissionsCard filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <FuelSourceGraph filter={filter} />
              </Grid>
              <Grid xs={12} item>
                <FuelSourceTargetGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <EmissionChart filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <TotalEmissionChart filter={filter} />
              </Grid>
              <Grid xs={12} marginTop={-2} mb={-2} item>
                <Typography variant="h6">Drill Down Charts</Typography>
              </Grid>
              <Grid xs={4.8} item>
                <InitiativeTrackingGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EnergyMaterialConsumption filter={filter} />
              </Grid>
            </Grid>
          );
        }
        if (value === 1) {
          return (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              className={classes.gridContainer}
            >
              <Grid xs={4.8} item>
                <DiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EmployeeHireResignGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <DevelopmentTrainingGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <SocialEngagementGraph filter={filter} />
              </Grid>
              <Grid xs={12} item>
                <EmployeeTurnoverGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <BoardDiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <AntiCorruptionDisclosureGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <ManagementDiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <AntiCorruptionTrainingGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <EconomicGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EthicalBehaviourGraph filter={filter} />
              </Grid>
            </Grid>
          );
        }
        break;
      case "proxy_sustainability_manager":
        if (value === 0) {
          return (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              className={classes.gridContainer}
            >
              <Grid xs={4.8} item>
                <EmissionsCard filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <FuelSourceGraph filter={filter} />
              </Grid>
              <Grid xs={12} item>
                <FuelSourceTargetGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <EmissionChart filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <TotalEmissionChart filter={filter} />
              </Grid>
              <Grid xs={12} marginTop={-2} mb={-2} item>
                <Typography variant="h6">Drill Down Charts</Typography>
              </Grid>
              <Grid xs={4.8} item>
                <InitiativeTrackingGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EnergyMaterialConsumption filter={filter} />
              </Grid>
            </Grid>
          );
        }
        if (value === 1) {
          return (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              className={classes.gridContainer}
            >
              <Grid xs={4.8} item>
                <DiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EmployeeHireResignGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <DevelopmentTrainingGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <SocialEngagementGraph filter={filter} />
              </Grid>
              <Grid xs={12} item>
                <EmployeeTurnoverGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <BoardDiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <AntiCorruptionDisclosureGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <ManagementDiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <AntiCorruptionTrainingGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <EconomicGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EthicalBehaviourGraph filter={filter} />
              </Grid>
            </Grid>
          );
        }
        break;
      case "read_only_user":
        if (value === 0) {
          return (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              className={classes.gridContainer}
            >
              <Grid xs={4.8} item>
                <EmissionsCard filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <FuelSourceGraph filter={filter} />
              </Grid>
              <Grid xs={12} item>
                <FuelSourceTargetGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <EmissionChart filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <TotalEmissionChart filter={filter} />
              </Grid>
              <Grid xs={12} marginTop={-2} mb={-2} item>
                <Typography variant="h6">Drill Down Charts</Typography>
              </Grid>
              <Grid xs={4.8} item>
                <InitiativeTrackingGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EnergyMaterialConsumption filter={filter} />
              </Grid>
            </Grid>
          );
        }
        if (value === 1) {
          return (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              className={classes.gridContainer}
            >
              <Grid xs={4.8} item>
                <DiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EmployeeHireResignGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <DevelopmentTrainingGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <SocialEngagementGraph filter={filter} />
              </Grid>
              <Grid xs={12} item>
                <EmployeeTurnoverGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <BoardDiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <AntiCorruptionDisclosureGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <ManagementDiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <AntiCorruptionTrainingGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <EconomicGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EthicalBehaviourGraph filter={filter} />
              </Grid>
            </Grid>
          );
        }
        break;
      case "admin":
        return (
          <Grid
            container
            rowSpacing={-2}
            columnSpacing={5}
            className={classes.gridContainer}
          >
            <Grid xs={4.8} item>
              <RoleDistributionGraph filter={filter} />
            </Grid>
            <Grid xs={7.2} item>
              <FuelSourceGraph filter={filter} />
            </Grid>
            <Grid xs={4.8} item>
              <GeographicalChart filter={filter} />
            </Grid>
            <Grid xs={7.2} item>
              <AuditTrends filter={filter} />
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid
            container
            rowSpacing={-2}
            columnSpacing={5}
            className={classes.gridContainer}
          >
            <Grid xs={4.8} item>
              <EmissionChart filter={filter} />
            </Grid>
            <Grid xs={7.2} item>
              <FuelSourceGraph filter={filter} />
            </Grid>
            <Grid xs={4.8} item>
              <GeographicalChart filter={filter} />
            </Grid>
            <Grid xs={7.2} item>
              <AuditTrends filter={filter} />
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
      {role !== "read_only_user" && role !== "auditor" && role !== "proxy_sustainability_manager" && 
      <DashboardNotification notificationData={notificationData}/>}
        {role === "read_only_user" && (
          <Card className={classes.companyCard}>
            <Typography variant="h4" className={classes.companyName}>
              {company.name}
            </Typography>
          </Card>
        )}
        <DashboardHeader
          onApplyFilter={onApplyFilter}
          value={value}
          setValue={setValue}
        />
        <Statistics filter={filter} />
        {renderChartsBasedOnRole()}
      </Container>
    </DashboardLayout>
  );
};

export default Dashboard;
