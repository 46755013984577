import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from "@mui/material"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    registerables as registerablesJS
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import _ from 'lodash';

import { getHireResignGraph } from '../../../redux/actions';

import useStyles from './styles'
import { getCookie } from '../../../services/cookie';

ChartJS.register(...registerablesJS)
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const EmployeeHireResignGraph = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch();
    const role = getCookie("role");
    const companyId = getCookie("companyId");

    const employeeData = useSelector(state => state.dashboard.getHireResignGraph.data);
    const employeeDataStatus = useSelector(state => state.dashboard.getHireResignGraph.data.status);

    useEffect(() => {
        dispatch(getHireResignGraph(props.filter,role,companyId));
    }, [dispatch, props.filter]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    fontColor: 'rgba(0,0,0,.4)',
                },
                align: 'end',
                position: 'bottom',
            },
            title: {
                align: 'start',
                display: true,
                text: 'Employee Hire & Resign Trends',
                color: 'black',
                padding: {
                    bottom: 20,
                },
                font: {
                    size: 18,
                    family: 'Inter',
                    weight: '400',
                },
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Total No. of Employees',
                    color: '#4F4F4F',
                    font: {
                        size: 12,
                        family: 'Inter',
                        weight: '400',
                    },
                },
                ticks: {
                    color: "black",
                    stepSize: 10, 
                    beginAtZero: true 
                }
            },
            x:{
                title: {
                    display: true,
                    text: 'Duration',
                    color: '#4F4F4F',
                    font: {
                        size: 12,
                        family: 'Inter',
                        weight: '400',
                    },
                },
                ticks:{
                    color: "black",
                }
            }
        },
    };
    

    const data = {
        labels: _.map(employeeData?.hired_trends, (item) => item.year),
        datasets: [
            {
                label: 'Employee Hire',
                data: _.map(employeeData?.hired_trends, (item) => item.hired),
                color: "black",
                backgroundColor: '#846BB5',
                borderColor: '#846BB5', 
                pointBackgroundColor: '#6FBFBA',
                pointRadius: 5
            },
            {
                label: 'Employee Resign',
                data: _.map(employeeData?.resigned_trends, (item) => item.resigned),
                color: "black",
                backgroundColor: '#FFC757',
                borderColor: '#FFC757', 
                pointBackgroundColor: '#6FBFBA',
                pointRadius: 5
            },
        ],
    };


    return (
        <Paper className={classes.container} >
            {employeeDataStatus === true ? 
                <Line options={options} data={data} height={360} width={560} />
                 :
                "Data loading..."
            }
        </Paper >
    )
}

export default EmployeeHireResignGraph