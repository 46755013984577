import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'

import { ActionTypes } from '../constants/actions';
import { STATUS } from '../constants'
import { parseError } from '../../services/client';

export const superAdminState = {
  addSuperAdmin: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  editSuperAdmin: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  deleteSuperAdmin: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  superadminList: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  superadminDetails: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  updateCompanySubscription: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  createBusinessAdmin: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  adminUserUpdateStatus: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  createSubscriptionPlan: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getSubscriptionDetailsSuperadmin: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getAllSubscriptionPlansSuperadmin: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getSuperadminReportsData: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getOrganizationCountry: {
    data: {country_facility_data:{},country_data:{}},
    status: STATUS.IDLE,
    message: ''
  },
  getSuperadminTickets: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getSuperadminTicketDetails: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  addSuperadminResponse: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  closeSuperadminTicket: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getAdminReportDetails: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  generateAnnouncement: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  updateAnnouncement: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getAnnouncement: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  listAnnouncement: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getCountryFacilityAggregate: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
  getRoleBasedAggregate: {
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
};

const superAdminActions = {
  superAdmin: handleActions(
    {
      [ActionTypes.ADD_SUPER_ADMIN]: (state, { payload }) =>
        immutable(state, {
          addSuperAdmin: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.ADD_SUPER_ADMIN_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          addSuperAdmin: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.ADD_SUPER_ADMIN_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addSuperAdmin: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.EDIT_SUPER_ADMIN]: (state, { payload }) =>
        immutable(state, {
          editSuperAdmin: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.EDIT_SUPER_ADMIN_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          editSuperAdmin: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.EDIT_SUPER_ADMIN_FAILURE]: (state, { payload }) =>
        immutable(state, {
          editSuperAdmin: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.DELETE_SUPER_ADMIN]: (state, { payload }) =>
        immutable(state, {
          deleteSuperAdmin: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.DELETE_SUPER_ADMIN_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          deleteSuperAdmin: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.DELETE_SUPER_ADMIN_FAILURE]: (state, { payload }) =>
        immutable(state, {
          deleteSuperAdmin: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_SUPERADMIN_LIST]: (state, { payload }) =>
        immutable(state, {
          superadminList: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_SUPERADMIN_LIST_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          superadminList: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_SUPERADMIN_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
          superadminList: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_SUPERADMIN]: (state, { payload }) =>
        immutable(state, {
          superadminDetails: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_SUPERADMIN_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          superadminDetails: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_SUPERADMIN_FAILURE]: (state, { payload }) =>
        immutable(state, {
          superadminDetails: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.UPDATE_COMPANY_SUBSCRIPTION]: (state, { payload }) =>
        immutable(state, {
          updateCompanySubscription: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_COMPANY_SUBSCRIPTION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          updateCompanySubscription: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.UPDATE_COMPANY_SUBSCRIPTION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          updateCompanySubscription: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
      [ActionTypes.CREATE_BUSINESS_ADMIN]: (state, { payload }) =>
        immutable(state, {
          createBusinessAdmin: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.CREATE_BUSINESS_ADMIN_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          createBusinessAdmin: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.CREATE_BUSINESS_ADMIN_FAILURE]: (state, { payload }) =>
        immutable(state, {
          createBusinessAdmin: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.CREATE_SUBSCRIPTION_PLAN]: (state, { payload }) =>
        immutable(state, {
          createSubscriptionPlan: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.CREATE_SUBSCRIPTION_PLAN_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          createSubscriptionPlan: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.CREATE_SUBSCRIPTION_PLAN_FAILURE]: (state, { payload }) =>
        immutable(state, {
          createSubscriptionPlan: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.CLEAR_SUBSCRIPTION_STATUS]: (state, { payload }) =>
        immutable(state, {
          createSubscriptionPlan: {
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.GET_ALL_SUBSCRIPTION_PLANS_SUPERADMIN]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getAllSubscriptionPlansSuperadmin: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_ALL_SUBSCRIPTION_PLANS_SUPERADMIN_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getAllSubscriptionPlansSuperadmin: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_ALL_SUBSCRIPTION_PLANS_SUPERADMIN_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getAllSubscriptionPlansSuperadmin: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_SUBSCRIPTION_DETAILS_SUPERADMIN]: (state, { payload }) =>
        immutable(state, {
          getSubscriptionDetailsSuperadmin: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_SUBSCRIPTION_DETAILS_SUPERADMIN_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getSubscriptionDetailsSuperadmin: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_SUBSCRIPTION_DETAILS_SUPERADMIN_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          getSubscriptionDetailsSuperadmin: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.RESET_CREATE_BUSINESS_STATUS]: (state, { payload }) =>
        immutable(state, {
          createBusinessAdmin: {
            status: { $set: STATUS.IDLE },
          },
        }),

      [ActionTypes.RESET_SUPER_ADMIN_STATUS]: (state, { payload }) =>
        immutable(state, {
          addSuperAdmin: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          editSuperAdmin: {
            status: { $set: STATUS.IDLE },
            data: { $set: {} },
            message: { $set: "" },
          },
          deleteSuperAdmin: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          superadminDetails: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          updateCompanySubscription: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          adminUserUpdateStatus: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          addSuperadminResponse: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          closeSuperadminTicket: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          generateAnnouncement: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          updateAnnouncement: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          getAnnouncement: {
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
        }),

      [ActionTypes.UPDATE_BUSINESS_ADMIN_STATUS]: (state, { payload }) =>
        immutable(state, {
          adminUserUpdateStatus: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.UPDATE_BUSINESS_ADMIN_STATUS_SUCCESS]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          adminUserUpdateStatus: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.UPDATE_BUSINESS_ADMIN_STATUS_FAILURE]: (
        state,
        { payload }
      ) =>
        immutable(state, {
          adminUserUpdateStatus: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
        [ActionTypes.GET_SUPER_ADMIN_REPORT]: (
          state,
          {payload}
        ) =>
          immutable(state, {
            getSuperadminReportsData: {
              status: { $set: STATUS.RUNNING },
            },
          }),
        [ActionTypes.GET_SUPER_ADMIN_REPORT_SUCCESS]: (
          state,
          { payload }
        ) =>
          immutable(state, {
            getSuperadminReportsData: {
              status: { $set: STATUS.SUCCESS },
              data: { $set: payload },
            },
          }),
        [ActionTypes.GET_SUPER_ADMIN_REPORT_FAILURE]: (
          state,
          { payload }
        ) =>
          immutable(state, {
            getSuperadminReportsData: {
              status: { $set: STATUS.ERROR },
              message: { $set: parseError(payload) },
            },
          }),
          
          [ActionTypes.GET_SUPER_ADMIN_ORGANIZATION_COUNTRY]: (
            state,
            {payload}
          ) =>
            immutable(state, {
              getOrganizationCountry: {
                status: { $set: STATUS.RUNNING },
              },
            }),
          [ActionTypes.GET_SUPER_ADMIN_ORGANIZATION_COUNTRY_SUCCESS]: (
            state,
            { payload }
          ) =>
            immutable(state, {
              getOrganizationCountry: {
                status: { $set: STATUS.SUCCESS },
                data: { $set: payload },
              },
            }),
          [ActionTypes.GET_SUPER_ADMIN_ORGANIZATION_COUNTRY_FAILURE]: (
            state,
            { payload }
          ) =>
            immutable(state, {
              getOrganizationCountry: {
                status: { $set: STATUS.ERROR },
                message: { $set: parseError(payload) },
              },
            }),

            [ActionTypes.GET_SUPERADMIN_TICKETS]: (
              state,
              {payload}
            ) =>
              immutable(state, {
                getSuperadminTickets: {
                  status: { $set: STATUS.RUNNING },
                },
              }),
            [ActionTypes.GET_SUPERADMIN_TICKETS_SUCCESS]: (
              state,
              { payload }
            ) =>
              immutable(state, {
                getSuperadminTickets: {
                  status: { $set: STATUS.SUCCESS },
                  data: { $set: payload },
                },
              }),
            [ActionTypes.GET_SUPERADMIN_TICKETS_FAILURE]: (
              state,
              { payload }
            ) =>
              immutable(state, {
                getSuperadminTickets: {
                  status: { $set: STATUS.ERROR },
                  message: { $set: parseError(payload) },
                },
              }),

              [ActionTypes.GET_SUPERADMIN_TICKET_DETAILS]: (
                state,
                {payload}
              ) =>
                immutable(state, {
                  getSuperadminTicketDetails: {
                    status: { $set: STATUS.RUNNING },
                  },
                }),
              [ActionTypes.GET_SUPERADMIN_TICKET_DETAILS_SUCCESS]: (
                state,
                { payload }
              ) =>
                immutable(state, {
                  getSuperadminTicketDetails: {
                    status: { $set: STATUS.SUCCESS },
                    data: { $set: payload },
                  },
                }),
              [ActionTypes.GET_SUPERADMIN_TICKET_DETAILS_FAILURE]: (
                state,
                { payload }
              ) =>
                immutable(state, {
                  getSuperadminTicketDetails: {
                    status: { $set: STATUS.ERROR },
                    message: { $set: parseError(payload) },
                  },
                }),

                [ActionTypes.ADD_SUPERADMIN_RESPONSE]: (
                  state,
                  {payload}
                ) =>
                  immutable(state, {
                    addSuperadminResponse: {
                      status: { $set: STATUS.RUNNING },
                    },
                  }),
                [ActionTypes.ADD_SUPERADMIN_RESPONSE_SUCCESS]: (
                  state,
                  { payload }
                ) =>
                  immutable(state, {
                    addSuperadminResponse: {
                      status: { $set: STATUS.SUCCESS },
                      data: { $set: payload },
                    },
                  }),
                [ActionTypes.ADD_SUPERADMIN_RESPONSE_FAILURE]: (
                  state,
                  { payload }
                ) =>
                  immutable(state, {
                    addSuperadminResponse: {
                      status: { $set: STATUS.ERROR },
                      message: { $set: parseError(payload) },
                    },
                  }),

                  [ActionTypes.CLOSE_SUPERADMIN_TICKET]: (
                    state,
                    {payload}
                  ) =>
                    immutable(state, {
                      closeSuperadminTicket: {
                        status: { $set: STATUS.RUNNING },
                      },
                    }),
                  [ActionTypes.CLOSE_SUPERADMIN_TICKET_SUCCESS]: (
                    state,
                    { payload }
                  ) =>
                    immutable(state, {
                      closeSuperadminTicket: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                      },
                    }),
                  [ActionTypes.CLOSE_SUPERADMIN_TICKET_FAILURE]: (
                    state,
                    { payload }
                  ) =>
                    immutable(state, {
                      closeSuperadminTicket: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) },
                      },
                    }),

                    [ActionTypes.GET_ADMIN_REPORT_DETAILS]: (
                      state,
                      {payload}
                    ) =>
                      immutable(state, {
                        getAdminReportDetails: {
                          status: { $set: STATUS.RUNNING },
                        },
                      }),
                    [ActionTypes.GET_ADMIN_REPORT_DETAILS_SUCCESS]: (
                      state,
                      { payload }
                    ) =>
                      immutable(state, {
                        getAdminReportDetails: {
                          status: { $set: STATUS.SUCCESS },
                          data: { $set: payload },
                        },
                      }),
                    [ActionTypes.GET_ADMIN_REPORT_DETAILS_FAILURE]: (
                      state,
                      { payload }
                    ) =>
                      immutable(state, {
                        getAdminReportDetails: {
                          status: { $set: STATUS.ERROR },
                          message: { $set: parseError(payload) },
                        },
                      }),

                      [ActionTypes.GENERATE_ANNOUNCEMENT]: (
                        state,
                        {payload}
                      ) =>
                        immutable(state, {
                          generateAnnouncement: {
                            status: { $set: STATUS.RUNNING },
                          },
                        }),
                      [ActionTypes.GENERATE_ANNOUNCEMENT_SUCCESS]: (
                        state,
                        { payload }
                      ) =>
                        immutable(state, {
                          generateAnnouncement: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload },
                          },
                        }),
                      [ActionTypes.GENERATE_ANNOUNCEMENT_FAILURE]: (
                        state,
                        { payload }
                      ) =>
                        immutable(state, {
                          generateAnnouncement: {
                            status: { $set: STATUS.ERROR },
                            message: { $set: parseError(payload) },
                          },
                        }),

                        [ActionTypes.UPDATE_ANNOUNCEMENT]: (
                          state,
                          {payload}
                        ) =>
                          immutable(state, {
                            updateAnnouncement: {
                              status: { $set: STATUS.RUNNING },
                            },
                          }),
                        [ActionTypes.UPDATE_ANNOUNCEMENT_SUCCESS]: (
                          state,
                          { payload }
                        ) =>
                          immutable(state, {
                            updateAnnouncement: {
                              status: { $set: STATUS.SUCCESS },
                              data: { $set: payload },
                            },
                          }),
                        [ActionTypes.UPDATE_ANNOUNCEMENT_FAILURE]: (
                          state,
                          { payload }
                        ) =>
                          immutable(state, {
                            updateAnnouncement: {
                              status: { $set: STATUS.ERROR },
                              message: { $set: parseError(payload) },
                            },
                          }),

                          [ActionTypes.GET_ANNOUNCEMENT]: (
                            state,
                            {payload}
                          ) =>
                            immutable(state, {
                              getAnnouncement: {
                                status: { $set: STATUS.RUNNING },
                              },
                            }),
                          [ActionTypes.GET_ANNOUNCEMENT_SUCCESS]: (
                            state,
                            { payload }
                          ) =>
                            immutable(state, {
                              getAnnouncement: {
                                status: { $set: STATUS.SUCCESS },
                                data: { $set: payload },
                              },
                            }),
                          [ActionTypes.GET_ANNOUNCEMENT_FAILURE]: (
                            state,
                            { payload }
                          ) =>
                            immutable(state, {
                              getAnnouncement: {
                                status: { $set: STATUS.ERROR },
                                message: { $set: parseError(payload) },
                              },
                            }),

                            [ActionTypes.LIST_ANNOUNCEMENT]: (
                              state,
                              {payload}
                            ) =>
                              immutable(state, {
                                listAnnouncement: {
                                  status: { $set: STATUS.RUNNING },
                                },
                              }),
                            [ActionTypes.LIST_ANNOUNCEMENT_SUCCESS]: (
                              state,
                              { payload }
                            ) =>
                              immutable(state, {
                                listAnnouncement: {
                                  status: { $set: STATUS.SUCCESS },
                                  data: { $set: payload },
                                },
                              }),
                            [ActionTypes.LIST_ANNOUNCEMENT_FAILURE]: (
                              state,
                              { payload }
                            ) =>
                              immutable(state, {
                                listAnnouncement: {
                                  status: { $set: STATUS.ERROR },
                                  message: { $set: parseError(payload) },
                                },
                              }),

                              [ActionTypes.GET_COUNTRY_FACILITY_AGGREGATE]: (
                                state,
                                {payload}
                              ) =>
                                immutable(state, {
                                  getCountryFacilityAggregate: {
                                    status: { $set: STATUS.RUNNING },
                                  },
                                }),
                              [ActionTypes.GET_COUNTRY_FACILITY_AGGREGATE_SUCCESS]: (
                                state,
                                { payload }
                              ) =>
                                immutable(state, {
                                  getCountryFacilityAggregate: {
                                    status: { $set: STATUS.SUCCESS },
                                    data: { $set: payload },
                                  },
                                }),
                              [ActionTypes.GET_COUNTRY_FACILITY_AGGREGATE_FAILURE]: (
                                state,
                                { payload }
                              ) =>
                                immutable(state, {
                                  getCountryFacilityAggregate: {
                                    status: { $set: STATUS.ERROR },
                                    message: { $set: parseError(payload) },
                                  },
                                }),

                                [ActionTypes.GET_ROLE_BASED_AGGREGATE]: (
                                  state,
                                  {payload}
                                ) =>
                                  immutable(state, {
                                    getRoleBasedAggregate: {
                                      status: { $set: STATUS.RUNNING },
                                    },
                                  }),
                                [ActionTypes.GET_ROLE_BASED_AGGREGATE_SUCCESS]: (
                                  state,
                                  { payload }
                                ) =>
                                  immutable(state, {
                                    getRoleBasedAggregate: {
                                      status: { $set: STATUS.SUCCESS },
                                      data: { $set: payload },
                                    },
                                  }),
                                [ActionTypes.GET_ROLE_BASED_AGGREGATE_FAILURE]: (
                                  state,
                                  { payload }
                                ) =>
                                  immutable(state, {
                                    getRoleBasedAggregate: {
                                      status: { $set: STATUS.ERROR },
                                      message: { $set: parseError(payload) },
                                    },
                                  }),  
    },
    superAdminState
  ),
};

export default superAdminActions;
