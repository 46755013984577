import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";

import { getRefreshToken, refreshToken } from "./redux/actions";
import RootNavigation from "./routes/RouteNavigation";
import theme from "./config/theme";
import { STATUS } from "./redux/constants";
import { getCookie } from "./services/cookie";
import CookieConsent from "react-cookie-consent";
import { CircularProgress } from "@mui/material";

function App() {
  const dispatch = useDispatch();
  const refreshStatus = useSelector((state) => state.auth.refreshStatus);
  const authToken = useSelector((state) => state.auth.isAuthenticated);

  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    const checkTokenExpiry = () => {
      const expiryTime = getCookie("access_token_expiry");
      const currentTime = new Date().getTime() / 1000;

      if (Number(expiryTime) - currentTime < 60000) {
        if (authToken) dispatch(getRefreshToken());
      }
    };

    const tokenCheckInterval = setInterval(checkTokenExpiry, 3000);

    return () => {
      clearInterval(tokenCheckInterval);
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(refreshToken());
  }, [dispatch]);

  useEffect(() => {
    if (auth.proxySustainabilityManagerLogin === STATUS.SUCCESS) {
      window.location.pathname = "dashboard";
    }else if (auth.tradeLoginArroUser === STATUS.SUCCESS) {
      window.location.pathname = "trade/dashboard-internal";
    }
  }, [auth]);

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          {refreshStatus === STATUS.RUNNING ? (
            <div
              style={{
                width: "100%",
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <RootNavigation />
          )}
          {authToken && (
            <CookieConsent
              location="bottom"
              buttonText="I understand"
              cookieName="myCookieConsent"
              style={{ background: "#5A404E", zIndex: 2000 }}
              buttonStyle={{
                background: "#8C2148",
                color: "#ffffff",
                fontSize: "16px",
                padding: "6px 10px",
                borderRadius: "8px",
              }}
              expires={150}
            >
              We use essential cookies to ensure the basic functionality of our
              website and to keep it secure. These cookies are necessary for the
              proper operation of the site and cannot be turned off. By
              continuing to use our website, you agree to the use of these
              essential cookies. Thank you for your understanding
              and cooperation.
            </CookieConsent>
          )}
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
