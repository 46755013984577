import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import CeroInput from "../../../components/CeroInput";
import CeroSelect from "../../../components/CeroSelect";
import { months, sampleYear } from "../../../constants";
import { addOffsetValidation } from "./schema";
import {
  addCarbonOffset,
  getCountryList,
  getPrograms,
  listCarbonOffsetPrograms,
  resetCarbonOffset,
} from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";
import useStyles from "./styles";
import CeroPopUp from "../../../components/CeroPopUp";
import CeroButton from "../../../components/CeroButton";
import CeroAutoComplete from "../../../components/CeroAutoComplete";

const CreateCarbonOffsetPopup = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { isOpen } = props;
  const addCarbonOffsetStatus = useSelector(
    (state) => state.carbonOffset.addCarbonOffset.status
  );
  const statusMessage = useSelector(
    (state) => state.carbonOffset.addCarbonOffset.message
  );
  // const countryData = useSelector(
  //   (state) => state.listings.getOrganizationCountry.data.country_data
  // );
  const programTypes = useSelector(
    (state) => state.carbonOffset.getProgramTypes.data
  );
  const programs = useSelector((state) => state.carbonOffset.getPrograms.data);
  const countryListData = useSelector(state => state.listings.countryList.data)
  const countryList = countryListData.map(item => ({ id: item.code, label: item.name }));
  const countryFacilityData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_facility_data
  );
  const facilityList = [];

for (const country in countryFacilityData) {
    const facilities = countryFacilityData[country];
    for (const facility_name in facilities) {
        const facility_id = facilities[facility_name];
        facilityList.push({ id: facility_id, label: facility_name });
    }
}
  const [selectedFacilitites, setFacility] = useState(facilityList);
  const programTypeOptions = programTypes.map((item) => ({
    key: item.id,
    value: item.name,
  }));
const programOptions = [];
const statusList = [];

  programs.forEach(item => {
    // Add key-value pair to programOptions
    programOptions.push({
      key: item.id,
      value: item.name,
    });

    // Check if the item has a status key and if it's true, add to statusList
    if (item.status === false) {
      statusList.push(item.name);
    }
  });
  
  const yearList = sampleYear()
  // const countryOptions = Object.keys(countryData).map((item) => ({
  //   key: countryData[item],
  //   value: item,
  // }));
  const isButtonLoading = addCarbonOffsetStatus === STATUS.RUNNING;

  const addCarbonOffsetForm = useFormik({
    initialValues: {
      program_name: "",
      country: "",
      program_type: "",
      program: "",
      start_year: 0,
      start_month: 0,
      installed_capacity: "",
      number_of_panels: "",
      description: "",
      facility: "",
    },
    validationSchema: addOffsetValidation,
    onSubmit: () => {},
  });
  const updateSelectedFacilities = (country) =>{
    const facilityList = []
    const facilities = countryFacilityData[country];
    for (const facility_name in facilities) {
        const facility_id = facilities[facility_name];
        facilityList.push({ id: facility_id, label: facility_name });
    }
    setFacility(facilityList)
  }
  const onAddData = () => {
    const payload = {
      name: addCarbonOffsetForm.values.program_name,
      country: addCarbonOffsetForm.values.country,
      choose_program_id: addCarbonOffsetForm.values.program,
      program_type_id: addCarbonOffsetForm.values.program_type,
      start_year: addCarbonOffsetForm.values.start_year,
      start_month: addCarbonOffsetForm.values.start_month,
      description: addCarbonOffsetForm.values.description,
      facility: addCarbonOffsetForm.values.facility,
      program_specific_data: [
        {
          data_name: "number of panels",
          value: addCarbonOffsetForm.values.number_of_panels,
        },
        {
          data_name: "Installed capacity (KW)",
          value: addCarbonOffsetForm.values.installed_capacity,
        }
      ]
    };
    dispatch(addCarbonOffset(payload));
  };
  
  const onClose = useCallback(() => {
    addCarbonOffsetForm.resetForm({});
    props.onClose();
  }, [addCarbonOffsetForm, props]);

  useEffect(() => {
    addCarbonOffsetForm.values.program_type && dispatch(getPrograms(addCarbonOffsetForm.values.program_type))
  }, [dispatch, addCarbonOffsetForm.values.program_type]);

  useEffect(() => {
    dispatch(getCountryList())
}, [dispatch])

  useEffect(() => {
    if (addCarbonOffsetStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Data added successfully", {
        variant: "success",
      });
      dispatch(listCarbonOffsetPrograms());
      dispatch(resetCarbonOffset());
      onClose();
    } else if (addCarbonOffsetStatus === STATUS.ERROR) {
      enqueueSnackbar(
        statusMessage
          ? statusMessage
          : "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetCarbonOffset());
    }
  }, [addCarbonOffsetStatus, enqueueSnackbar, onClose, dispatch]);

  const getContent = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CeroInput
              classes={{ container: classes.selectContainer }}
              required
              id="program_name"
              name="program_name"
              label="Program Name"
              fullWidth
              value={addCarbonOffsetForm.values.program_name}
              onChange={addCarbonOffsetForm.handleChange}
              onBlur={addCarbonOffsetForm.handleBlur}
              error={
                addCarbonOffsetForm.touched.program_name &&
                addCarbonOffsetForm.errors.program_name
              }
            />
            <CeroAutoComplete
              id="facility"
              label="Facility"
              required
              selectedValue={addCarbonOffsetForm.values.facility || ""}
              onChange={(e, value) =>  addCarbonOffsetForm.setFieldValue('facility', value ? value.id : undefined)}
              onBlur={addCarbonOffsetForm.handleBlur}
              error={
                addCarbonOffsetForm.touched.facility &&
                addCarbonOffsetForm.errors.facility
              }
              options={selectedFacilitites}
              sortOption={true}
            />
            {addCarbonOffsetForm.values.program_type ===
              "66b3244376be3c74cae48014" && (
              <CeroSelect
                classes={{ container: classes.selectContainer }}
                required
                id="program"
                name="program"
                label="Choose Program"
                fullWidth
                options={programOptions}
                selectedValue={addCarbonOffsetForm.values.program || ""}
                onChange={addCarbonOffsetForm.handleChange}
                onBlur={addCarbonOffsetForm.handleBlur}
                error={
                  addCarbonOffsetForm.touched.program &&
                  addCarbonOffsetForm.errors.program
                }
                disableItem={statusList}
              />
            )}
            {addCarbonOffsetForm.values.program && (
              <>
                <CeroSelect
                  classes={{ container: classes.selectContainer }}
                  id="start_year"
                  name="start_year"
                  label="Start Year"
                  fullWidth
                  options={yearList}
                  selectedValue={addCarbonOffsetForm.values.start_year || ""}
                  onChange={addCarbonOffsetForm.handleChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.start_year &&
                    addCarbonOffsetForm.errors.start_year
                  }
                />
                
              </>
            )}
            {addCarbonOffsetForm.values.program ===
              "66b324a676be3c74cae48015" && (
              <CeroInput
                classes={{ container: classes.selectContainer }}
                required
                id="number_of_panels"
                name="number_of_panels"
                label="No. of panels installed"
                fullWidth
                type="number"
                value={addCarbonOffsetForm.values.number_of_panels}
                onChange={addCarbonOffsetForm.handleChange}
                onBlur={addCarbonOffsetForm.handleBlur}
                error={
                  addCarbonOffsetForm.touched.number_of_panels &&
                  addCarbonOffsetForm.errors.number_of_panels
                }
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <CeroAutoComplete
              id="country"
              label="Country"
              required
              selectedValue={addCarbonOffsetForm.values.country || ""}
              onChange={(e, value) =>  {addCarbonOffsetForm.setFieldValue('country', value ? value.id : undefined); updateSelectedFacilities(value ? value.id : undefined);
              }}
              onBlur={addCarbonOffsetForm.handleBlur}
              error={
                addCarbonOffsetForm.touched.country &&
                addCarbonOffsetForm.errors.country
              }
              options={countryList}
            />
            <CeroSelect
              classes={{ container: classes.selectContainer }}
              required
              id="program_type"
              name="program_type"
              label="Program Type"
              fullWidth
              options={programTypeOptions}
              selectedValue={addCarbonOffsetForm.values.program_type || ""}
              onChange={addCarbonOffsetForm.handleChange}
              onBlur={addCarbonOffsetForm.handleBlur}
              error={
                addCarbonOffsetForm.touched.program_type &&
                addCarbonOffsetForm.errors.program_type
              }
            />
            {addCarbonOffsetForm.values.program ===
                  "66b324a676be3c74cae48015" && (
                  <CeroInput
                    classes={{ container: classes.selectContainer }}
                    required
                    id="installed_capacity"
                    name="installed_capacity"
                    label="Installed Capacity (kW)"
                    fullWidth
                    type="number"
                    value={addCarbonOffsetForm.values.installed_capacity}
                    onChange={addCarbonOffsetForm.handleChange}
                    onBlur={addCarbonOffsetForm.handleBlur}
                    error={
                      addCarbonOffsetForm.touched.installed_capacity &&
                      addCarbonOffsetForm.errors.installed_capacity
                    }
                  />
                )}
            {addCarbonOffsetForm.values.program && (
              <CeroSelect
                classes={{ container: classes.selectContainer }}
                id="start_month"
                name="start_month"
                label="Start Month"
                fullWidth
                options={months}
                selectedValue={addCarbonOffsetForm.values.start_month || ""}
                onChange={addCarbonOffsetForm.handleChange}
                onBlur={addCarbonOffsetForm.handleBlur}
                error={
                  addCarbonOffsetForm.touched.start_month &&
                  addCarbonOffsetForm.errors.start_month
                }
              />
            )}
            
          </Grid>
        </Grid>
        {addCarbonOffsetForm.values.program && (
          <CeroInput
            classes={{ container: classes.selectContainer }}
            required
            id="description"
            name="description"
            label="Brief description about the project"
            fullWidth
            multiline
            rows="2"
            type="number"
            value={addCarbonOffsetForm.values.description}
            onChange={addCarbonOffsetForm.handleChange}
            onBlur={addCarbonOffsetForm.handleBlur}
            error={
              addCarbonOffsetForm.touched.description &&
              addCarbonOffsetForm.errors.description
            }
          />
        )}
        <Grid item xs={12}>
          <CeroButton
            buttonText={isButtonLoading ? "Saving..." : "Save"}
            variant="contained"
            disabled={
              Object.keys(addCarbonOffsetForm.errors).length > 0 ||
              addCarbonOffsetForm.values.country === "" ||
              isButtonLoading
            }
            onClick={onAddData}
            className={classes.buttonPrimary}
          />
          <CeroButton
            buttonText="Cancel"
            variant="outlined"
            disabled={false}
            onClick={onClose}
            className={classes.buttonSecondary}
          />
        </Grid>
      </>
    );
  };

  return (
    <CeroPopUp
      primaryPopUp={{
        open: isOpen,
        onClose: onClose,
        content: getContent(),
        header: { title: "Add Carbon Offset Programs" },
      }}
      dialog={classes.popUpClass}
      showCloseButton={true}
    />
  );
};

export default CreateCarbonOffsetPopup;
